/* .nav-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-menu li {
    position: relative;
}

.nav-menu a {
    text-decoration: none;
    color: #000;
    padding: 10px;
}


.nav-menu {
    display: none;
}


.nav-menu.open {
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    background: white;
    width: 100%;
    z-index: 1000;
}


.menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}


.toggle-nav {
    cursor: pointer;
}


@media (max-width: 768px) {
    .nav-menu {
        flex-direction: column;
        background: #fff;
        padding: 10px;
    }

    .nav-menu li {
        border-bottom: 1px solid #ddd;
    }

    .nav-menu li a {
        padding: 15px;
        display: block;
    }

    .toggle-nav {
        display: block;
    }

    .brand-logo img {
        width: 150px;

    }
}


@media only screen and (max-width: 767px) {
    .brand-logo {
        margin-left: -153px;
        margin-top: -7px;
    } */



/* .nav-menu .logo-desktop {
    display: none;
}


@media (min-width: 769px) {
    .logo-desktop {
        display: block;
    }
}


@media (max-width: 768px) {
    .nav-menu.open .logo-desktop {
        display: none;
    }
} */

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: left;
    text-align: justify;
}

#header {
    background-color: black;
    overflow: hidden;
}
.navbar {
    padding: 15px 0;
}
.navbar-brand {
    
}
.navbar a {
    text-decoration: none;
}
h2.logo {
    font-size: 28px;
    font-weight: 400;
    color: #fff;
}
h2.logo strong {
    font-size: 35px;
    color: #EDF828;
    font-weight: 600;
}
h2.center-logo {
    margin: 0 25px;
}
#navbarSupportedContent {
    justify-content: center;
}
ul.navbar-nav {

    margin-top: 17px;
}
ul.navbar-nav li.nav-item {
    margin-right: 25px;
}
ul.navbar-nav li.nav-item:last-child {
    margin-right: 0;
}
ul.navbar-nav li.nav-item a.nav-link {
    color: #fff;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: #EDF828;
}
ul.navbar-nav li.nav-item a.nav-link:hover {
    color: #EDF828;
}

ul.navbar-nav li.nav-item {
    margin-right: 40px; /* space of content */
}

ul.navbar-nav li.nav-item a.nav-link {
    padding: 10px 15px; /* Add padding for more clickable area */
    color: #fff;
    font-size: 16px; /* Adjust font size if needed */
}

.navbar {
    padding: 20px 0; /* Increase padding for overall spacing */
}

#header {
    background-color: black;
    overflow: hidden;
    padding: 10px 0; /* Add padding to the header */
}

ul.navbar-nav {
    margin-top: 0px; /* Adjust margin to align the items vertically */
}

.navbar-light .navbar-nav .nav-link:hover, 
.navbar-light .navbar-nav .nav-link.active {
    color: #EDF828; /* Highlight effect on hover or active link */
    transition: color 0.3s ease-in-out; /* Smooth color transition */
}

.navbar-collapse {
    justify-content: center;
}

.brand-logo img {
    margin-top: 5px; /* Add spacing to align the logo with links */
    width: 180px; /* Adjust size if needed */
}

body {
    overflow-x: hidden; 
}

.container-fluid {
    
    width: 95%!important;
}

.title-3 {
    text-align: center;
   
}

.topsec{
    margin-top: 106px;
}

.desc-box {
    margin: 0 auto;
    width: 100%;
}



/* Responsive Design */
@media (max-width: 1200px) {
    .col-lg-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 768px) {
    .col-lg-4, .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .title-3 {
        margin-bottom: 20px;
    }
    
    .desc-box {
        width: 80%;
    }
}

@media (max-width: 576px) {
    .title-3 h2 {
        font-size: 1.5rem;
    }

    .title-3 {
        margin-bottom: 15px;
    }

    .overlay-background i {
        font-size: 1.5rem;
    }
    
    .desc-box {
        width: 95%;
    }
}

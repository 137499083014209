.footer-social ul li a {
    color: #fff; /* Default color for icons */
    font-size: 18px;
    margin-right: 10px;
    transition: color 0.3s ease;
}

/* Facebook - official blue */
.footer-social ul li a .fa-facebook-f {
    color: #1877f2;
}

/* Instagram - official gradient colors */
.footer-social ul li a .fa-instagram {
    background: linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* YouTube - official red */
.footer-social ul li a .fa-youtube {
    color: #ff0000;
}

/* Hover effect: slightly darker colors */
.footer-social ul li a:hover .fa-facebook-f {
    color: #145dbf;
}

.footer-social ul li a:hover .fa-instagram {
    background: linear-gradient(45deg, #d9832c, #c7563b, #c1203b, #a81b58, #991276);
}

.footer-social ul li a:hover .fa-youtube {
    color: #cc0000;
}

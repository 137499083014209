/* General Container */
.terms-container {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 5rem;
    padding: 30px;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    text-align: left; /* Align content left */
}

/* Main heading styling */
.termsAndConditionsHeading {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 40px;
    text-align: center;
    color: #333;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 1px;
}

/* Welcome section */
.spangleWelcome {
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    color: #555;
    margin-bottom: 15px;
    font-style: italic;
}

/* Introductory paragraph */
.termsParagraphIntro {
    margin: 15px;
    font-size: 16px;
    color: #777;
    line-height: 1.6;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd; /* Light separator */
}

/* Section headers */
.serviceLeadingSection {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 25px;
    text-align: left;
}

/* Numbered section heading styling */
.sn, .st {
    font-size: 38px;
    font-weight: 700;
    color: #333;
    padding-right: 15px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 10px;
}

/* Paragraph styling */
.spl {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    text-align: left;
}

/* Service Information containers */
.serviceInfoContainer {
    position: relative;
    margin-top: 15px;
    padding-left: 40px;
    padding-right: 20px;
    border-left: 5px solid #eee;
    padding-top: 12px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    text-align: left;
    transition: box-shadow 0.3s ease-in-out;
}

/* Hover effect for service info containers */
.serviceInfoContainer:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Service lead text (subheadings in each section) */
.serviceLead {
    font-weight: 700;
    font-size: 18px;
    color: #333;
    margin-bottom: 8px;
    text-align: left;
}

/* Service details text (content under each subheading) */
.serviceDetails {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    text-align: left;
}

/* Vertical lines with color based on section */
.secionLine {
    height: 50px;
    width: 5px;
    position: absolute;
    left: 0;
    top: 10px;
    border-radius: 3px;
}

/* Blue section lines */
.lineColorBlue {
    background-color: #2a90d9;
}

/* Orange section lines */
.lineColorOrange {
    background-color: #f49c42;
}

/* Green section lines */
.lineColorGreen {
    background-color: #4caf50;
}

/* Close Terms button */
.closeTerms {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color: #f00;
    border-bottom: 1px solid #ccc;
    padding: 12px 0;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

/* Change color of close button on hover */
.closeTerms:hover {
    color: #ff3333;
}

/* Fade-in animation */
.fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.8s;
    animation-timing-function: ease-out;
}

/* Add more spacing between subheadings and paragraphs */
.termsParagraph {
    margin-top: 20px; /* Adds more space above the paragraph */
    margin-bottom: 20px; /* Adds more space below the paragraph */
    font-size: 16px;
    color: #777;
    line-height: 1.8; /* Increases line height for better readability */
    text-align: left;
}

.termsParagraph strong {
    display: block; /* Makes the strong tags block-level so they get their own line */
    margin-top: 15px; /* Adds space above each subheading */
    margin-bottom: 5px; /* Adds space below each subheading */
    font-weight: 600; /* Slightly increases the weight of the subheadings */
}

/* Styling for the unordered list to add spacing between items */
.policyList {
    list-style-type: none;
    padding-left: 0;
}

.policyList li {
    margin-bottom: 10px; /* Adds more space between the list items */
    font-size: 16px;
    color: #555;
    line-height: 1.6;
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Responsive Styles for smaller screens */
@media (max-width: 1024px) {
    .terms-container {
        padding: 20px;
    }
    .termsAndConditionsHeading {
        font-size: 32px;
    }
    .sn, .st {
        font-size: 32px;
    }
    .serviceInfoContainer {
        padding-left: 25px;
        padding-right: 25px;
    }
    .serviceLead {
        font-size: 16px;
    }
    .serviceDetails {
        font-size: 14px;
    }
    .spl {
        font-size: 14px;
    }
    .closeTerms {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .terms-container {
        max-width: 100%;
        padding: 15px;
    }
    .termsAndConditionsHeading {
        font-size: 28px;
    }
    .sn, .st {
        font-size: 28px;
    }
    .termsParagraphIntro {
        font-size: 14px;
    }
    .serviceLead {
        font-size: 14px;
    }
    .serviceDetails {
        font-size: 13px;
    }
    .spl {
        font-size: 13px;
    }
    .serviceInfoContainer {
        padding-left: 15px;
        padding-right: 15px;
    }
    .closeTerms {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .termsAndConditionsHeading {
        font-size: 24px;
    }
    .sn, .st {
        font-size: 24px;
    }
    .serviceInfoContainer {
        padding-left: 10px;
        padding-right: 10px;
    }
    .serviceLead {
        font-size: 12px;
    }
    .serviceDetails {
        font-size: 12px;
    }
    .spl {
        font-size: 12px;
    }
    .closeTerms {
        font-size: 12px;
    }
}

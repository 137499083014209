.team-section {
    background-image: url('../public/assets/images/cab/grey-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  
}


.facts-section {
    background-image: url('../public/assets/images/cab/grey-bg.jpg');
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    position: relative; 
}


.facts-section .bg-img {
    display: none; 
    
}



.breadcrumb-section {
    height: auto; 
    position: relative;
    overflow: hidden;
}

.breadcrumb-section img.bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}


@media (max-width: 768px) {
    .breadcrumb-section {
        height: 200px; 
    }
}

@media (max-width: 768px) {
    .gallery-box {
        text-align: center;
        margin: 0 auto;
    }
    .gallery-img img {
        width: 100%;
        height: auto;
    }
    .gallery-content h5 {
        font-size: 1rem;
    }
    .btn.btn-solid {
        font-size: 0.875rem;
    }
}

/* Blogdetails.css */
.blog-details-section {
    padding: 60px 15px;
    background-color: #f9f9f9;
}



.blog-header {
    text-align: center;
    margin-bottom: 20px;
}

.blog-title {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 5px;
}

.blog-date {
    font-size: 14px;
    color: #777;
    margin-top: 0;
}

.blog-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.blog-content {
    font-size: 18px;
    line-height: 1.8;
    color: #444;
    text-align: justify;
}

.loading,
.error-message,
.no-content {
    text-align: center;
    font-size: 18px;
    color: #333;
    margin-top: 50px;
}

@media (max-width: 768px) {
    

    .blog-title {
        font-size: 26px;
    }

    .blog-content {
        font-size: 16px;
    }
}

.terms-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.termsAndConditionsHeading {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.spangleWelcome {
    font-size: 1.2em;
    color: #666;
    text-align: center;
    margin-bottom: 20px;
}

.termsParagraphIntro {
    font-size: 1em;
    color: #555;
    text-align: center;
    margin-bottom: 30px;
}

.sectionHeading {
    font-size: 1.4em;
    margin-bottom: 10px;
    color: #444;
}

.termsParagraph {
    font-size: 1em;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.6;
}

.policyList {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
}

.policyList li {
    margin-bottom: 10px;
    font-size: 1em;
    color: #555;
}

.sectionLine {
    height: 2px;
    margin: 20px 0;
}

.lineColorBlue {
    background-color: #007bff;
}

.lineColorOrange {
    background-color: #ff9800;
}

.lineColorGreen {
    background-color: #4caf50;
}

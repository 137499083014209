.termsAndConditions {
    padding: 20px;
    line-height: 1.6;
}

.sectionHeading {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.termsParagraph {
    font-size: 1em;
    margin-bottom: 20px;
}

.sectionLine {
    height: 2px;
    margin: 20px 0;
}

.lineColorBlue {
    background-color: #007bff;
}

.lineColorOrange {
    background-color: #ff9800;
}

.lineColorGreen {
    background-color: #4caf50;
}

.counter {
    text-align: center;
}

button {
    margin: 5px;
    padding: 2px 11px;
    font-size: 15px;
}



body {
    font-family: 'Sans-serif';
}

h1 {
    color: teal;
    text-shadow: 1px 2px 3px #333;
}

.datainputs {
    margin-top: 20px;
}

.datainputs input {
    font-size: 18px;
    line-height: 21px;
    color: #3D3D3D;
    height: 50px;
    padding: 0px 20px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 1px #D8D8D8;
    margin: 0px 30px 20px 0px !important;
}

input:focus {
    outline: none;
}

.add_input,
.inputRemove {
    display: inline-block;
    color: #3d3d3d;
    text-align: center;
    text-decoration: none;
    width: auto;
    height: 40px;
    line-height: 40px;
    border: 2px solid #3d3d3d;
    padding: 0px 15px;
    border-radius: 5px;
}

.inputRemove {
    cursor: pointer;
}




.popup {
    position: absolute;
    top: 106%;
    width: 25%;
    left: 59%;
    right: 0;
    z-index: 10;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 100%;
    overflow: visible;
    transition: all 0.3s ease;
}

.popup-content {
    display: flex;
    flex-direction: column;
}

.room-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.room-control button {
    background-color: #ddd;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.room-control span {
    margin: 0 10px;
}


@media (max-width: 991px) {
    .popup {
        width: 50%;
        left: 25%;
        padding: 15px;
    }
}

/* Media query for mobile devices */
@media (max-width: 767px) {
    .popup {
        width: 90%;
        left: 5%;
        top: 90%;
        padding: 10px;
    }
}


@media (max-width: 575px) {
    .popup {
        width: 85%;
        left: 7.5%;
        top: 114%;
        padding: 8px;
    }
}
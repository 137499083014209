.about-section .about-column-upper {
    margin-top: -64px; /* Raise the first column slightly */
}

.about-section .about-column-lower {
    margin-top: 20px; /* Lower the second column slightly */
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-section .about-column-upper,
    .about-section .about-column-lower {
        margin-top: 0; /* Reset margin for smaller screens */
    }

    .about-section .about-text p {
        text-align: center; /* Center-align text for mobile view */
    }
}


.about_section {
    padding: 20px 0;
}


.about_section1 .row {
    display: flex;
    justify-content: center;
    gap: 10px; /* Adjust spacing between images */
}

.about_img {
    position: relative;
}

.square-img {
  
    height: auto;
    aspect-ratio: 1 / 1; /* Ensures the image is a square */
    object-fit: cover;
    border-radius: 8px; /* Optional: Adds rounded corners */
    transition: transform 0.3s ease;
}

.square-img:hover {
    transform: scale(1.05); /* Optional: Adds a zoom effect on hover */
}

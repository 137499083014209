/* Add this to your CSS file */
.blog-image img {
    width: 100%;  /* Make image full width of the container */
    height: auto;  /* Maintain aspect ratio */
    object-fit: cover;  /* Ensures the image covers the space without distortion */
}

/* Optional: Set a max-height to prevent images from becoming too large on larger screens */
.blog-image img {
    max-height: 300px;  /* Adjust max height as necessary */
}

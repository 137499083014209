

.category-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .img-category {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .img-category img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .content-category {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
  
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .rating {
    display: flex;
    gap: 5px;
  }
  
  .category-box a {
    text-decoration: none;
    color: inherit;
  }
  
  /* Custom arrows */
  .slick-prev, .slick-next {
    top: 50%;
    z-index: 1;
  }
  
  .slick-prev {
    left: -40px;
  }
  
  .slick-next {
    right: -40px;
  }
  
  /* Additional styles for responsiveness and appearance */
  @media (max-width: 1024px) {
    .slick-prev, .slick-next {
      left: -30px;
      right: -30px;
    }

    .category-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      overflow: hidden;
    }
  }
  
  @media (max-width: 600px) {
    .slick-prev, .slick-next {
      left: 20rem;
      right: -20px;
    }

    .category-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      overflow: hidden;
    }
  }
  
.video-section {
    position: relative;
    overflow: hidden;
    background: #000; /* Ensures the section has a background */
  }
  
  .video-section .bg-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .video-section .basic-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
  }
  
  .video-section .basic-section h2 {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .video-section .basic-section h4 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
  
  .video-section .video-icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 3px solid #fff;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .video-section .video-icon span {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: transparent;
  }
  
  .video-section .animation-circle-inverse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: transparent;
    animation: spin 1.5s linear infinite;
  }
  
  .video-section .animation-circle-inverse i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .modal-content {
    position: relative;
    border: none;
    border-radius: 0;
  }
  
  .modal-body {
    padding: 0;
  }
  
  .modal-body iframe {
    width: 100%;
    height: 60vh; /* Adjust height as needed */
    border: none;
  }
  
  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #000;
  }
  
  .btn-close span {
    display: block;
  }
  
  @media (max-width: 768px) {
    .video-section .basic-section h2 {
      font-size: 1.8rem;
    }
    
    .video-section .basic-section h4 {
      font-size: 1.2rem;
    }
  }
  